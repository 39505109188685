import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { IoMdStar, IoMdCheckmark } from 'react-icons/io';
import { displayMoney } from '../helpers/utils';
import { API_URL } from '../Const';
import axios from 'axios';
import '../styles/style.scss';
import useActive from '../hooks/useActive';
import SectionsHead from '../Components/Common/SectionsHead';
import RelatedProductsSlider from '../Components/Sliders/RelatedProductsSlider';
import ProductSummary from '../Components/ProductCard/ProductSummary';
import OurServices from '../Components/TestimonialContainer/OurServices';
import { WhatsappShareButton, WhatsappIcon } from 'react-share';
import YouTubeVideo from '../Components/YouTubeVideo/YouTubeVideo';
import { useNavigate } from "react-router-dom";

const ProductDetailsPage = () => {
    const [productDetailsData, setProductDetailsData] = useState(null);
    const [previewImg, setPreviewImg] = useState([]);
    const { handleActive, activeClass } = useActive(0);
    const { productId } = useParams();
    let navigate = useNavigate();

    useEffect(() => {
        getProductDetailsData();
        handleActive(0);
    }, [productId]);
    
    const getProductDetailsData = async () => {
        try {
        const productId1 = window.location.href.split("product-details/")[1];
        const response = await axios.get(`${API_URL}getProductById/`+productId1);
            setProductDetailsData(response.data.result_Arr[0]);
            setPreviewImg((response.data.result_Arr[0].product_primary_image_url));
        } catch (error) {
        console.log(error);
        }
    };

    const handlePreviewImg = (i) => {
        setPreviewImg(productDetailsData && productDetailsData.product_images?productDetailsData.product_images[i].product_images_url:null);
        handleActive(i);
    };

    const handleAddItem = async (product_id) => {
        const userId = localStorage.getItem('userId');
        if(userId == null || userId === "" || userId === "-1"){
            navigate("loginPage");
        } else {
            const sendAuth = await axios.post(API_URL+"addToMyCart",
            {
                user_id: userId,
                product_id: product_id,
                quantity: 1
            })
            const receive = await sendAuth.data
            if (receive.status === 'success') {
                alert("Product added to cart");
                //TODO change the cart count
            } else if(receive.status === 'failure'){
                alert(receive.message);
            } else {
                alert("Something went wrong, Please try again");
            }
        }
    };

    return (
        productDetailsData?
        <>
        <section id="product_details" className="section">
                <div className="container">
                    <div className="wrapper prod_details_wrapper">

                        {/*=== Product Details Left-content ===*/}
                        <div className="prod_details_left_col">
                            <div className="prod_details_tabs">
                                {
                                    productDetailsData.product_images ? productDetailsData.product_images.map((img, i) => (
                                        <div
                                            key={i}
                                            className={`tabs_item ${activeClass(i)}`}
                                            onClick={() => handlePreviewImg(i)}
                                        >
                                            <img src={API_URL+img.product_images_url} alt="product-img" />
                                        </div>
                                    ))
                                    : null
                                }
                            </div>
                            <figure className="prod_details_img">
                                <img style={{height:'auto'}} src={API_URL+previewImg} alt="product-img" />
                            </figure>
                        </div>
                        <div className="prod_details_right_col">
                            <h1 className="prod_details_title" style={styles.oneLinesStyle}>{productDetailsData?productDetailsData.product_name:null}</h1>
                            <h4 className="prod_details_info" style={styles.twoLinesStyle}>{productDetailsData?productDetailsData.product_description:null}</h4>

                            <div className="prod_details_ratings">
                                <span className="rating_star">
                                    {
                                        [...Array(productDetailsData.avg_rating)].map((_, i) => <IoMdStar style={{color: "yellow"}} key={i} />)
                                    }
                                </span>
                                <span>|</span>
                                <Link to="*">{productDetailsData.no_of_ratings} Ratings</Link>
                            </div>

                            <div className="separator"></div>

                            <div className="prod_details_price">
                                <div className="price_box">
                                    <h2 className="price">
                                        {displayMoney(productDetailsData.product_current_price)} &nbsp;
                                        <small className="del_price"><del>{displayMoney(productDetailsData.product_actual_price)}</del></small>
                                    </h2>
                                    <p className="saved_price">You save: {productDetailsData.product_actual_price - productDetailsData.product_current_price} ({productDetailsData.product_offer_percentage}%)</p>
                                    <span className="tax_txt">(Inclusive of all taxes)</span>
                                </div>

                                <div className="badge">
                                    <span><IoMdCheckmark /> In Stock</span>
                                </div>
                            </div>

                            <div className="separator"></div>

                            <div className="prod_details_offers">
                                <h4>Offers and Discounts</h4>
                                <ul>
                                    <li>No Cost EMI on Credit Card</li>
                                    <li>Pay Later & Avail Cashback</li>
                                </ul>
                            </div>

                            <div className="separator"></div>
                            
                            <div className="prod_details_buy_btn" style={{display: "flex"}}>
                                <button
                                    type="button"
                                    className="btn"
                                    onClick={() => handleAddItem(productDetailsData.product_id)}
                                >
                                    Add to cart
                                </button>
                                <WhatsappShareButton
                                    url={window.location.href}
                                    title={`Hi, I would like to buy ${productDetailsData ? productDetailsData.product_name : 'this product'}. Please share more information.`}
                                    style={{ paddingLeft: "20px"}}
                                    separator=" - "
                                >
                                    <WhatsappIcon size={32} round={true} />
                                </WhatsappShareButton>
                            </div>

                            
                            </div>
                        
                    </div>
                </div>
            </section>
            {
                productDetailsData.product_videos ? productDetailsData.product_videos.map((item, i) => (
                    <YouTubeVideo videoId={item.youtube_video_video_id} autoplay={item.youtube_video_autoplay} mute={item.youtube_video_mute} showRelatedVideo={item.youtube_video_show_related_videos} modestbranding={item.youtube_video_remove_yt_logo} loop={item.youtube_video_loop}/>
                ))
                : null
            }

            {/* TODO Need to show product description */}
            {/* <ProductSummary productDetailsData={productDetailsData} /> */}
            <section id="related_products" className="section">
            <div className="container">
                <SectionsHead heading="Related Products" />
                <RelatedProductsSlider />
            </div>
            <div className="container">
                <OurServices/>
            </div>
            </section>
            </>
            :null
    );
};

// export const displayMoney = (n) => {
//   const numFormat = new Intl.NumberFormat('en-IN', {
//       style: 'currency',
//       currency: 'INR',
//   });

//   return numFormat.format(n).split('.', 1);
// };

const styles = {
    twoLinesStyle: {
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
    },
    oneLinesStyle: {
        display: '-webkit-box',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
    },
};

export default ProductDetailsPage;