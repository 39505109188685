import React, {useEffect, useState} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, A11y } from 'swiper/modules';
import {API_URL} from '../../Const';
import axios from 'axios';
import ProductCard from '../ProductCard/ProductCard';

import 'swiper/scss';
import 'swiper/scss/pagination';


const RelatedProductsSlider = () => {

    const [randomProducts, setRandomProducts] = useState([]);


    useEffect(() => {
        getRelatedProducts();
        window.scroll(0, 0)
    }, []);

    const getRelatedProducts = async() => {
        //TODO: Need to change proper API for this
        await axios.get(API_URL+`getAllProductDetails`).then(function (response) {
            setRandomProducts(response.data.result_Arr);
        }).catch(function (error) {
            console.log(error);
        });
    }

    return (
        <Swiper
            modules={[Pagination, A11y]}
            spaceBetween={10}
            slidesPerView={"auto"}
            pagination={{ clickable: true }}
            breakpoints={{
                480: {
                    slidesPerView: 2,
                },
                768: {
                    slidesPerView: 2,
                },
                992: {
                    slidesPerView: 4,
                },
            }}
            className="related_swiper"
        >
            {
                randomProducts.map(product_details => (
                    <SwiperSlide key={product_details.product_id}>
                        <ProductCard product_details = {product_details} />
                    </SwiperSlide>
                ))
            }
        </Swiper>
    );
};

export default RelatedProductsSlider;