import React from 'react';
import { API_URL } from '../../../Const';
import { Link } from 'react-router-dom';
import { displayMoney } from '../../../helpers/utils';

const Product = ({item}) => {
    return (
        <div className="showcase">
            <Link to={`/product-details/${item.product_id}`}>
            <a href="#" className="showcase-img-box">
                <img src={API_URL+item.product_primary_image_url} alt="" width="70" className="showcase-img" />
            </a>
            </Link>
            <div className="showcase-content">
                <Link to={`/product-details/${item.product_id}`}>
                <h4 className="showcase-title">{item.product_name}</h4>
                </Link>
                <Link to={`/product-details/${item.product_id}`}>
                    <a href="#" className="showcase-category">{item.product_brand_name}</a>
                </Link>
                <div className="price-box">
                <p className="price">{displayMoney(item.product_current_price)}</p>
                <del>{displayMoney(item.product_actual_price)}</del>
                </div>
            </div>
        </div>
    );
};

export default Product;