import React from 'react';
import Product from './Product';

const Trending = ({homePageData}) => {
    return (
        <div className="product-showcase">
          <h2 className="title">Trending</h2>
          <div className="showcase-wrapper  has-scrollbar">
            <div className="showcase-container">
                {
                    homePageData && homePageData.trending ? 
                    homePageData.trending.map((item, index) => {
                        return(index % 2 == 0?<Product item = {item}/>: null);
                    }) : null
                }
            </div>
            <div className="showcase-container">
                {
                    homePageData && homePageData.trending ? 
                    homePageData.trending.map((item, index) => {
                        return(index % 2 == 1?<Product item = {item}/>: null);
                    }) : null
                }
            </div>
          </div>
        </div>
    );
};

export default Trending;