import React from 'react';
import Product from './Product';

const TopRated = ({homePageData}) => {
    return (
        <div className="product-showcase">
          <h2 className="title">Top Rated</h2>
          <div className="showcase-wrapper  has-scrollbar">
            <div className="showcase-container">
                {
                    homePageData && homePageData.top_rated ? 
                    homePageData.top_rated.map((item, index) => {
                        return(index % 2 == 0?<Product item = {item}/>: null);
                    }) : null
                }
            </div>
            <div className="showcase-container">
                {
                    homePageData && homePageData.top_rated ? 
                    homePageData.top_rated.map((item, index) => {
                        return(index % 2 == 0?<Product item = {item}/>: null);
                    }) : null
                }
            </div>
          </div>
        </div>
    );
};

export default TopRated;