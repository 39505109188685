import React, { useContext } from 'react';
import { IoMdStar } from 'react-icons/io';
import { Link } from 'react-router-dom';
import { displayMoney } from '../../helpers/utils';
import cartContext from '../../contexts/cart/cartContext';
import useActive from '../../hooks/useActive';
import {API_URL} from '../../Const';
import axios from 'axios'
import { useNavigate } from "react-router-dom";

const ProductCard = ({product_details}) => {

    // const { id, images, title, info, finalPrice, originalPrice, rateCount, path } = props;

    const { active, handleActive, activeClass } = useActive(false);

    let navigate = useNavigate();

    const handleAddItem = async (product_id) => {
        const userId = localStorage.getItem('userId');
        if(userId == null || userId === "" || userId === "-1"){
            navigate("loginPage");
        } else {
            const sendAuth = await axios.post(API_URL+"addToMyCart",
            {
                user_id: userId,
                product_id: product_id,
                quantity: 1
            })
            const receive = await sendAuth.data
            if (receive.status === 'success') {
                alert("Product added to cart");
                //TODO change the cart count
            } else if(receive.status === 'failure'){
                alert(receive.message);
            } else {
                alert("Something went wrong, Please try again");
            }
        }
    };

    const newPrice = displayMoney(product_details.product_current_price);
    const oldPrice = displayMoney(product_details.product_actual_price);


    return (
        <>
            <div className="card products_card">
                <figure className="products_img">
                    <Link to={`/product-details/${product_details.product_id}`}>
                        <img src={API_URL+product_details.product_primary_image_url} alt="product-img" />
                    </Link>
                </figure>
                <div className="products_details">
                    <span className="rating_star">
                        {
                            [...Array(product_details.avg_rating)].map((_, i) => <IoMdStar style={{color: "yellow"}} key={i} />)
                        }
                    </span>
                    <h3 className="products_title">
                        <Link to={`/product-details/${product_details.product_id}`}>{product_details.product_name}</Link>
                    </h3>
                    <h5 className="products_info">{product_details.product_description}</h5>
                    <div className="separator"></div>
                    <h2 className="products_price">
                        {newPrice} &nbsp;
                        <small><del>{oldPrice}</del></small>
                    </h2>
                    <button
                        type="button"
                        className={`btn products_btn ${activeClass(product_details.product_id)}`}
                        onClick={() => handleAddItem(product_details.product_id)}
                    >
                        {active ? 'Added' : 'Add to cart'}
                    </button>
                </div>
            </div>
        </>
    );
};

export default ProductCard;