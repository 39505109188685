import React, {useState} from 'react';
import HeaderTopBar from './HeaderTopBar';
import HeaderDesktopMenu from './HeaderDesktopMenu';
import HeaderMain from './HeaderMain';
import NotificationToast from '../Alerts/NotificationToast'
import NewsLetterModal from '../Alerts/NewsLetterModal';
import MobileBottomNavigation from '../MobileNavigation/MobileBottomNavigation';
import MobileNavigationMenu from '../MobileNavigation/MobileNavigationMenu';

const HeaderContainer = ({toggleSideDrawer, toggleSideDrawerMenu, issideDrawerMenuActive, favoriteCount, cartCount}) => {
    const [isMenuActive, setIsMenuActive] = useState(false);

    const handleMenuOpen = () => setIsMenuActive(true);
    const handleMenuClose = () => setIsMenuActive(false);
    return (
        <>
            <div className={`overlay ${isMenuActive ? 'active' : ''}`} onClick={handleMenuClose}></div>
                {/* <NewsLetterModal/>
                <NotificationToast/> */}
                <header>
                    {/* <HeaderTopBar/> */}
                    <HeaderMain favoriteCount = {favoriteCount} cartCount = {cartCount}/>
                    {/* <HeaderDesktopMenu/> */}
                    <MobileBottomNavigation toggleSideDrawer={toggleSideDrawer} toggleSideDrawerMenu = {toggleSideDrawerMenu} favoriteCount = {favoriteCount} cartCount = {cartCount}/>
                    <MobileNavigationMenu issideDrawerMenuActive = {issideDrawerMenuActive} toggleSideDrawerMenu ={toggleSideDrawerMenu}/>
                </header>
        </>
    );
};

export default HeaderContainer;