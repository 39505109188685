import React, { useState, useEffect, useRef } from 'react';
import './LoginPage.css';
import log from './img/log.svg';
import register from './img/register.svg';
import axios from 'axios'
import { useNavigate } from "react-router-dom";
import { API_URL, SIGN_IN_TEXT, SIGN_UP_TEXT } from '../../Const';

const LoginPage = () => {
  const [signInData, setSignInData] = useState({ email: '', password: '' });
  const [signUpData, setSignUpData] = useState({ username: '', email: '', password: '' });
  let navigate = useNavigate(); 

  const signInBtnRef = useRef(null);
  const signUpBtnRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const signInBtn = signInBtnRef.current;
    const signUpBtn = signUpBtnRef.current;
    const loginContainer = containerRef.current;

    if (signInBtn && signUpBtn && loginContainer) {
      signUpBtn.addEventListener('click', () => {
        loginContainer.classList.add('sign-up-mode');
      });

      signInBtn.addEventListener('click', () => {
        loginContainer.classList.remove('sign-up-mode');
      });
    }

    return () => {
      if (signInBtn && signUpBtn && loginContainer) {
        signUpBtn.removeEventListener('click', () => {
          loginContainer.classList.add('sign-up-mode');
        });

        signInBtn.removeEventListener('click', () => {
          loginContainer.classList.remove('sign-up-mode');
        });
      }
    };
  }, []);

  const validateSignIn = () => {
    let valid = true;

    if (!signInData.email) {
      alert("Email is required");
      valid = false;
    }
    if (!signInData.password) {
      alert("Password is required");
      valid = false;
    }
    return valid;
  };

  const validateSignUp = () => {
    let valid = true;

    if (!signUpData.username) {
      alert("Username is required");
      valid = false;
    }
    if (!signUpData.email) {
      alert("Email is required");
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(signUpData.email)) {
      alert("Email is invalid");
      valid = false;
    }
    if (!signUpData.password) {
      alert("Password is required");
      valid = false;
    }
    return valid;
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    if (validateSignIn()) {
      const sendAuth = await axios.post(API_URL+"userLogin",
        {
          user_email_id: signInData.email,
          user_password: signInData.password
        })
      const receive = await sendAuth.data
      if (receive.status === 'success') {
        alert("Successfully logged in");
        localStorage.setItem('userId', receive.result_Arr[0].user_id)
        navigate("/");
      } else if(receive.status === 'failure'){
        alert(receive.message);
      } else {
        alert("Something went wrong, Please try again");
      }
      console.log("Sign in data:", signInData);
    }
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    if (validateSignUp()) {
      const sendAuth = await axios.post(API_URL+"addNewUserDetails",
        {
          user_name: signUpData.username,
          user_email_id: signUpData.email,
          user_password: signUpData.password
        })
      const receive = await sendAuth.data
      if (receive.status === 'success') {
        alert("Registered Successfully");
        localStorage.setItem('userId', receive.user_id)
        navigate("/");
      } else if(receive.status === 'failure'){
        alert(receive.message);
      } else {
        alert("Something went wrong, Please try again");
      }
    }
  };

  return (
    <div className="loginContainer" ref={containerRef}>
      <div className="forms-loginContainer">
        <div className="signin-signup">
          <form action="#" className="sign-in-form" onSubmit={handleSignIn}>
            <h2 className="title">Sign in</h2>
            <div className="loginInput-field">
              <input
                type="text"
                placeholder="Email"
                value={signInData.email}
                onChange={(e) => setSignInData({ ...signInData, email: e.target.value })}
              />
            </div>
            <div className="loginInput-field">
              <input
                type="password"
                placeholder="Password"
                value={signInData.password}
                onChange={(e) => setSignInData({ ...signInData, password: e.target.value })}
              />
            </div>
            <input type="submit" value="Login" className="loginBtn solid" />
          </form>
          <form action="#" className="sign-up-form" onSubmit={handleSignUp}>
            <h2 className="title">Sign up</h2>
            <div className="loginInput-field">
              <input
                type="text"
                placeholder="Username"
                value={signUpData.username}
                onChange={(e) => setSignUpData({ ...signUpData, username: e.target.value })}
              />
            </div>
            <div className="loginInput-field">
              <input
                type="email"
                placeholder="Email"
                value={signUpData.email}
                onChange={(e) => setSignUpData({ ...signUpData, email: e.target.value })}
              />
            </div>
            <div className="loginInput-field">
              <input
                type="password"
                placeholder="Password"
                value={signUpData.password}
                onChange={(e) => setSignUpData({ ...signUpData, password: e.target.value })}
              />
            </div>
            <input type="submit" className="loginBtn" value="Sign up" />
          </form>
        </div>
      </div>

      <div className="panels-loginContainer">
        <div className="panel left-panel">
          <div className="content">
            <h3>New here?</h3>
            <p>{SIGN_IN_TEXT}</p>
            <button className="loginBtn transparent" id="sign-up-loginBtn" ref={signUpBtnRef}>
              Sign up
            </button>
          </div>
          <img src={log} className="image" alt="" />
        </div>
        <div className="panel right-panel">
          <div className="content">
            <h3>One of us?</h3>
            <p>{SIGN_UP_TEXT}</p>
            <button className="loginBtn transparent" id="sign-in-loginBtn" ref={signInBtnRef}>
              Sign in
            </button>
          </div>
          <img src={register} className="image" alt="" />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
