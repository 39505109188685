import React from 'react';
import { API_URL } from '../../Const';
import { Link } from 'react-router-dom';
import { displayMoney } from '../../helpers/utils';

const BannerCategory = ({homePageData}) => {
    return (
        <>
        {
            homePageData && homePageData.banner_category? 
            <div className="category">

                <div className="container">
            
                    <div className="category-item-container has-scrollbar">
                        {
                            homePageData.banner_category.map((item, index)=>{
                                return (
                                    <div className="category-item" key={item.sub_category_id}>
                                        <div className="category-img-box">
                                            <img src={API_URL+item.sub_category_image_url} alt={item.sub_category_name} width="30" />
                                        </div>
                                        <div className="category-content-box">
                                            <div className="category-content-flex">
                                                <h3 className="category-item-title">{item.sub_category_name}</h3>
                                                <p className="category-item-amount">({item.product_count})</p>
                                            </div>
                                            <Link to={`/all-products/${item.sub_category_id}`}>
                                                <a href="#" className="category-btn">Show All</a>
                                            </Link>
                                        </div>
                                    </div>
                                );
                            })
                        }
            
                    </div>
            
                </div>
            
            </div>
            : null
        }
        </>
    );
};

export default BannerCategory;