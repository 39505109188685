import React from 'react';
import { useNavigate } from "react-router-dom";
import {PRODUCT_LOGO} from '../../Const';

const HeaderMain = ({favoriteCount, cartCount}) => {
    const userId = localStorage.getItem('userId');

    let navigate = useNavigate();

    const goTo = (path) => {
        navigate(path);
    }

    return (
        <>
            <div className="header-main">

                <div className="container">

                    <a href="/" className="header-logo">
                        <img src={PRODUCT_LOGO} alt="Logo" width="50" height="50" />
                    </a>

                    <div className="header-search-container">

                        <input type="search" name="search" className="search-field" placeholder="Enter your product name..." />

                        <button className="search-btn">
                            <ion-icon name="search-outline"></ion-icon>
                        </button>

                    </div>

                    <div className="header-user-actions">
                    
                        <button className="action-btn">
                            <ion-icon name="home-outline" onClick={() => goTo("")}></ion-icon>
                        </button>

                        <button className="action-btn">
                            <ion-icon name="heart-outline" onClick={() => goTo("favoriteProducts")}></ion-icon>
                            <span className="count">{favoriteCount}</span>
                        </button>

                        <button className="action-btn">
                            <ion-icon name="bag-handle-outline" onClick={() => goTo("cartDetails")}></ion-icon>
                            <span className="count">{cartCount}</span>
                        </button>
                        
                        {
                            userId != null && userId !== "" ? (
                                <button className="action-btn" onClick={() => {
                                    localStorage.removeItem('userId');
                                    goTo("loginPage");
                                }}>
                                <ion-icon name="log-out-outline"></ion-icon>
                                </button>
                            ) : (
                                <button className="action-btn" onClick={() => {
                                    localStorage.removeItem('userId');
                                    goTo("loginPage");
                                }}>
                                <ion-icon name="person-outline"></ion-icon>
                                </button>
                            )
                        }

                        {
                            userId != null && userId !== "" ? (
                                <button className="action-btn">
                                    <ion-icon name="person-circle-outline" onClick={() => goTo("checkoutAddressDetails")}></ion-icon>
                                </button>
                            ) : null
                        }

                    </div>

                </div>

            </div>
        </>
    );
};

export default HeaderMain;