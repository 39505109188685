import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import HomePage from './Pages/HomePage';
import ProductDetailsPage from './Pages/ProductDetailsPage';
import ErrorPage from './Pages/ErrorPage';
import ProductsList from './Pages/ProductsList';
import LoginPage from './Pages/LoginPage/LoginPage';

import HeaderContainer from './Components/Header/HeaderContainer';
import FooterContainer from './Components/Footer/FooterContainer';

import FavoriteProducts from './Pages/FavoriteProducts/FavoriteProducts';
import CartPage from './Pages/Cart/CartPage';
import CheckoutAddressDetails from './Pages/Cart/CheckoutAddressDetails';
// import OrderHistory from './Pages/Cart/OrderHistory';
import {PRODUCT_NAME,} from './Const';
import {getFavProductCount, getMyCartCount} from './API/CommonAPIs';

const App = () => {
  const [issideDrawerActive, setSidebarDrawerActive] = useState(false);
  const [issideDrawerMenuActive, setiSsideDrawerMenuActive] = useState(false);

  useEffect(() => {
    document.title = PRODUCT_NAME;
  }, []);

  const toggleSideDrawer = () => {
    setSidebarDrawerActive(!issideDrawerActive);
  };

  const toggleSideDrawerMenu = () => {
    setiSsideDrawerMenuActive(!issideDrawerMenuActive);
  };

  return (
    <Router>
      <Layout
        toggleSideDrawer={toggleSideDrawer}
        toggleSideDrawerMenu={toggleSideDrawerMenu}
        issideDrawerMenuActive={issideDrawerMenuActive}
        issideDrawerActive={issideDrawerActive}
      />
    </Router>
  );
};

const Layout = ({ toggleSideDrawer, toggleSideDrawerMenu, issideDrawerMenuActive }) => {
  const [favoriteCount, setFavoriteCount] = useState(0);
  const [cartCount, setCartCount] = useState(0);
  const location = useLocation();
  const userId = localStorage.getItem('userId');

  useEffect(() => {
    if (userId) {
      const fetchFavProductCount = async () => {
        try {
          const count = await getFavProductCount();
          setFavoriteCount(count);
        } catch (error) {
          console.error("Error fetching favorite count:", error);
        }
      };

      const fetchCartCount = async () => {
        try{
          const count = await getMyCartCount();
          setCartCount(count);
        } catch (error) {
          console.error("Error fetching cart count:", error);
        }
      }
      
      fetchFavProductCount();
      fetchCartCount();
    }
  }, [userId]);


  const isExcludedPage = location.pathname === '/loginPage';

  return (
    <>
      {!isExcludedPage && (
        <HeaderContainer
          toggleSideDrawer={toggleSideDrawer}
          toggleSideDrawerMenu={toggleSideDrawerMenu}
          issideDrawerMenuActive={issideDrawerMenuActive}
          favoriteCount = {favoriteCount}
          cartCount = {cartCount}
        />
      )}
      <Routes>
        <Route path="/" index element={<HomePage />} />
        <Route path="/product-details/:productId" element={<ProductDetailsPage />} />
        <Route path="/all-products" element={<ProductsList />} />
        <Route path="/all-products/:categoryId" element={<ProductsList />} />
        <Route path="/loginPage" element={<LoginPage />} />
        <Route path="/favoriteProducts" element={<FavoriteProducts />} />
        <Route path="/cartDetails" element={<CartPage />} />
        <Route path="/checkoutAddressDetails" element={<CheckoutAddressDetails />} />
        {/* <Route path="/OrderHistory" element={<OrderHistory />} /> */}
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      {!isExcludedPage && <FooterContainer />}
    </>
  );
};

export default App;
