import {API_URL} from '../Const';
import axios from "axios";


export const getFavProductCount = async () => {
    const userId = localStorage.getItem('userId');
    const response = await axios.get(`${API_URL}getMyFavProductCount/${userId}`);
    return response.data.favproductcount;
};

export const getMyCartCount = async () => {
    const userId = localStorage.getItem('userId');
    const response = await axios.get(`${API_URL}getMyCartCount/${userId}`);
    return response.data.cartCount	;
};