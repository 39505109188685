import React, { useState } from "react";
import { API_URL } from '../../Const';
import { Link } from 'react-router-dom';

const SidebarCategory = ({ homePageData, toggleSideDrawer }) => {

  let toggles = homePageData && homePageData.sidebar_category ? homePageData.sidebar_category.map(() => ({ toggle: false })) : [];

  const handleToggle = (index) => {
    toggles[index].toggle = !toggles[index].toggle;
    document.getElementById(`submenu-${index}`).classList.toggle("active");
  };

  return (
    homePageData && homePageData.sidebar_category ? (
      <div className="sidebar-category">
        <div className="sidebar-top">
          <h2 className="sidebar-title">Category</h2>
            <Link to={`/all-products`}>
              <a href="#" className="category-btn">View All</a>
            </Link>
          <button
            onClick={toggleSideDrawer}
            className="sidebar-close-btn"
            data-mobile-menu-close-btn
          >
            <ion-icon name="close-outline"></ion-icon>
          </button>
        </div>

        <ul className="sidebar-menu-category-list">
          {homePageData.sidebar_category.map((item, index) => (
            <li key={index} className="sidebar-menu-category">
              <button
                className="sidebar-accordion-menu"
                onClick={() => handleToggle(index)}
              >
                <div className="menu-title-flex">
                  <img
                    src={API_URL + item.category_image_url}
                    alt={item.category_name}
                    width="20"
                    height="20"
                    className="menu-title-img"
                  />
                  <p className="menu-title">{item.category_name}</p>
                </div>

                <div>
                  {!toggles[index].toggle ? (
                    <ion-icon name="add-outline" className="add-icon active"></ion-icon>
                  ) : (
                    <ion-icon name="remove-outline" className="remove-icon active"></ion-icon>
                  )}
                </div>
              </button>

              <ul id={`submenu-${index}`} className={`sidebar-submenu-category-list ${toggles[index].toggle ? "active" : ""} data-accordion`}>
                {item.sub_category && item.sub_category.map((subCat, subIndex) => (
                  <li key={subIndex} className="sidebar-submenu-category">
                    <Link to={`/all-products/${subCat.sub_category_id}`}>
                    <a href="#" className="sidebar-submenu-title">
                      <p className="product-name">{subCat.sub_category_name}</p>
                      <data value={subCat.product_count} className="stock" title="Available Stock">
                        {subCat.product_count}
                      </data>
                    </a>
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    ) : null
  );
};

export default SidebarCategory;
