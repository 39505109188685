import React, { useState, useEffect } from "react";
import ProductCard from "./ProductCard";
import { API_URL } from "../../Const";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import './fav.css'

const FavoriteProducts = () => {
  const userId = localStorage.getItem("userId");
  const [favorites, setFavorites] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if(userId){
      getFavData(userId);
    }
  }, []);


  const getFavData = async (userId) => {
    try {
      const response = await axios.get(`${API_URL}getFavProductsByUserId/${userId}`);
      if (response.data.status === "success" && response.data.result_Arr) {
        const transformedFavorites = response.data.result_Arr.map((item) => ({
          id: item.product_id,
          favouriteId: item.favourite_id,
          name: item.product_name,
          price: `₹${item.product_current_price}`,
          rating: item.avg_rating || 0,
          image: `${API_URL}${item.product_primary_image_url}`,
        }));
        setFavorites(transformedFavorites);
      } else {
        console.error("Error fetching favorites.");
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const removeFromFavorites = async (favouriteId) => {
    setFavorites((prevFavorites) =>
      prevFavorites.filter((product) => product.favouriteId !== favouriteId)
    );

    try {
      const response = await axios.get(`${API_URL}removeFavProductsByFavId/${favouriteId}`);
      if (response.data.status === "success") {
        alert("Product removed successfully.");
        const userId = localStorage.getItem("userId");
        if (userId) {
          await getFavData(userId);
        }
      } else {
        console.error("Failed to remove product from the server.");
        const userId = localStorage.getItem("userId");
        if (userId) {
          await getFavData(userId);
        }
      }
    } catch (error) {
      console.error("API Error:", error);
      const userId = localStorage.getItem("userId");
      if (userId) {
        await getFavData(userId);
      }
    }
  };

  return (
    <div className="container">
      <h1 className="header">Favorite Products</h1>
      <div className="grid"> 
        {favorites.length > 0 ? (
          favorites.map((product) => (
            <ProductCard
              key={product.id}
              product={product}
              onRemove={() => removeFromFavorites(product.favouriteId)}
            />
          ))
        ) : (
          <p className="emptyText">Your favorite list is empty!</p>
        )}
      </div>
      <div className="shopNowContainer">
        {
          userId?(
            <button className="banner-btn shopNowButton" onClick={() => navigate("/")}>
              Shop Now
            </button>
          ):(
            <button  className="banner-btn shopNowButton" onClick={() => navigate("/loginPage")}>
              Login
            </button>
          )
        }
        
      </div>
    </div>
  );
};

// const styles = {
//   container: {
//     padding: "20px",
//     fontFamily: "Arial, sans-serif",
//   },
//   header: {
//     textAlign: "center",
//     marginBottom: "20px",
//   },
//   grid: {
//     display: "grid",
//     gridTemplateColumns: "repeat(5, 1fr)",
//     gap: "20px",
//   },
//   emptyText: {
//     gridColumn: "1 / -1",
//     textAlign: "center",
//     color: "#888",
//   },
//   shopNowContainer: {
//     marginTop: "20px",
//     textAlign: "center",
//     justifyContent: "center",
//     display: "flex"

//   },
//   shopNowButton: {
//     color: "#fff",
//     padding: "10px 20px",
//     fontSize: "16px",
//     border: "none",
//     borderRadius: "5px",
//     cursor: "pointer",
//   },
//   mediaQueries: {
//     '@media (max-width: 768px)': {

//       grid: {
//         gridTemplateColumns: "repeat(2, 1fr) !important" ,
//       },

//     },
//     '@media (max-width: 480px)': {
//       grid: {
//         gridTemplateColumns: "repeat(2, 1fr)",
//       },
//     }
//   }



// };

export default FavoriteProducts;
