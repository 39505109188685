import React, { useState, useEffect } from "react";

const CheckoutAddressDetails = () => {
  const [personalInfo, setPersonalInfo] = useState({
    firstName: "Natashia",
    lastName: "Khaleira",
    dateOfBirth: "1990-10-12",
    gender: "Female",
    email: "info@binary-fusion.com",
    phoneNumber: "82125545846",
  });

  const [address, setAddress] = useState({
    DoorNo: "United Kingdom",
    StreetName: "United Kingdom",
    Town: "United Kingdom",
    city: "Leeds, East London",
    Pincode: "ERT 1254",
    landmark: "ERT 1254",
  });

  const [profileImage, setProfileImage] = useState("your-profile-image-url.jpg");
  const [successMessage, setSuccessMessage] = useState({ personal: false, address: false });
  const [errors, setErrors] = useState({});

  const validatePersonalInfo = () => {
    const newErrors = {};

    if (!personalInfo.firstName.trim() || !/^[A-Za-z]+$/.test(personalInfo.firstName)) {
      newErrors.firstName = "First name must contain only letters.";
    }
    if (!personalInfo.lastName.trim() || !/^[A-Za-z]+$/.test(personalInfo.lastName)) {
      newErrors.lastName = "Last name must contain only letters.";
    }
    if (!personalInfo.email.trim() || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(personalInfo.email)) {
      newErrors.email = "Invalid email format.";
    }
    if (!personalInfo.gender.trim()) {
      newErrors.gender = "Gender is required.";
    }
    if (!personalInfo.phoneNumber.trim() || !/^\d{10}$/.test(personalInfo.phoneNumber)) {
      newErrors.phoneNumber = "Phone number must be exactly 10 digits.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e, section) => {
    const { name, value } = e.target;
    section === "personal"
      ? setPersonalInfo((prev) => ({ ...prev, [name]: value }))
      : setAddress((prev) => ({ ...prev, [name]: value }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSavePersonal = () => {
    if (validatePersonalInfo()) {
      setSuccessMessage((prev) => ({ ...prev, personal: true }));
      setTimeout(() => setSuccessMessage((prev) => ({ ...prev, personal: false })), 3000);
    }
  };

  const handleSaveAddress = () => {
    setSuccessMessage((prev) => ({ ...prev, address: true }));
    setTimeout(() => setSuccessMessage((prev) => ({ ...prev, address: false })), 3000);
  };

  useEffect(() => {
    const styleSheet = document.createElement("style");
    styleSheet.type = "text/css";
    styleSheet.innerText = styles;
    document.head.appendChild(styleSheet);
    return () => {
      document.head.removeChild(styleSheet);
    };
  }, []);

  return (
    <div className="container">
      {/* Profile Image Section */}
      <div className="profile-image-container">
        <img src={profileImage} alt="Profile" className="profile-image" />
        <label htmlFor="changeImage" className="edit-image-btn">
          Edit
        </label>
        <input
          id="changeImage"
          type="file"
          accept="image/*"
          className="change-image-input"
          onChange={handleImageChange}
        />
      </div>

      {/* Personal Information Section */}
      <div className="section">
        <div className="header">
          <h2>Personal Information</h2>
        </div>
        <div className="details-grid">
          {Object.keys(personalInfo).map((key) => (
            <div className="info" key={key}>
              <span>
                {key.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase())}
              </span>
              {key === "gender" ? (
                <select
                  name={key}
                  value={personalInfo[key]}
                  onChange={(e) => handleInputChange(e, "personal")}
                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
              ) : (
                <input
                  type={key === "dateOfBirth" ? "date" : "text"}
                  name={key}
                  value={personalInfo[key]}
                  onChange={(e) => handleInputChange(e, "personal")}
                />
              )}
              {errors[key] && <small className="error-message">{errors[key]}</small>}
            </div>
          ))}
        </div>
        <button className="save-button" onClick={handleSavePersonal}>
          Save Personal Info
        </button>
        {successMessage.personal && <div className="success-popup">Personal info updated successfully!</div>}
      </div>

      {/* Address Section */}
      <div className="section">
        <div className="header">
          <h2>Address</h2>
        </div>
        <div className="details-grid">
          {Object.keys(address).map((key) => (
            <div className="info" key={key}>
              <span>
                {key.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase())}
              </span>
              <input
                type="text"
                name={key}
                value={address[key]}
                onChange={(e) => handleInputChange(e, "address")}
              />
            </div>
          ))}
        </div>
        <button className="save-button" onClick={handleSaveAddress}>
          Save Address Info
        </button>
        {successMessage.address && <div className="success-popup">Address updated successfully!</div>}
      </div>
    </div>
  );
};



// CSS styles
const styles = `
  .container {
    font-family: Arial, sans-serif;
    max-width: auto;
    margin: 0 auto;
    padding: 20px;
  }

  .profile-image-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    flex-direction: column;
    align-items: center;
  }

  .profile-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
  }

  .change-image-btn {
    padding: 5px;
    background: #f97316;
    border: none;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    display: block;
  }

  .change-image-btn:hover {
    background: #ea580c;
  }

  .section {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 20px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .header h2 {
    margin: 0;
    font-size: 1.5em;
    color: #333;
  }

  .details-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 15px;
  }

  .info {
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }

  .info span {
    font-size: 0.9em;
    color: #555;
    margin-bottom: 5px;
  }

  .info p {
    font-size: 1em;
    color: #333;
    margin: 0;
  }

  .info input, .info select {
    font-size: 1em;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }

  .info input:focus, .info select:focus {
    outline: none;
    border-color: #f97316;
    box-shadow: 0 0 5px rgba(249, 115, 22, 0.3);
  }

/* Transaction Button Container */
.save-button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/* Transaction Button */
.save-button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #4CAF50; /* Green color */
  color: white;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin-left:auto;
}

/* Hover Effect */
.save-button:hover {
  background-color: #45a049;
  transform: scale(1.05); /* Slight zoom on hover */
}

/* Focus Effect */
.save-button:focus {
  outline: 2px solid #4CAF50;
  outline-offset: 2px;
}

/* Disabled State */
.save-button:disabled {
  background-color: #9E9E9E; /* Gray color for disabled */
  cursor: not-allowed;
  opacity: 0.7;
}

  .edit-image-btn {
    background: #f97316;
    border: none;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
  }

  .edit-image-btn:hover {
    background: #ea580c;
  }

  .change-image-input {
    display: none;
  }

  .success-popup {
    position: fixed;
    top: 20px;
    right: 20px;
    background: #4CAF50;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    animation: fadeInOut 3s ease-in-out;
  }

  @keyframes fadeInOut {
    0%, 100% { opacity: 0; }
    10%, 90% { opacity: 1; }
  }
  .error-message {
    color: red;
    font-size: 0.9em;
    margin-top: 5px;
    display: block;
  }
    
    
`;

export default CheckoutAddressDetails;
