import React from 'react';
import {PRODUCT_NAME} from '../../Const';

const FooterBottom = () => {
    return (
        <>
            <div className="footer-bottom">

                <div className="container">

                    <img src='/assets/images/payment.png' alt="payment method" className="payment-img" />

                    <p className="copyright">
                    Copyright &copy; <a href="#">{PRODUCT_NAME}</a> all rights reserved.
                    </p>

                </div>

            </div>
        </>
    );
};

export default FooterBottom;