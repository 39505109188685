import React from 'react';
import BrandDirectory from './BrandDirectory';
import FooterNav from './FooterNav';
import FooterBottom from './FooterBottom';
const Footer = () => {
    return (
        <footer>
            {/* <BrandDirectory/> */}
            {/* <FooterNav/> */}
            <FooterBottom/>
        </footer>
    );
};

export default Footer;