import React from 'react';

import OurServices from './OurServices';
import DiscountProduct from './DiscountProduct';
import Testimonials from './Testimonials';

const TestimonialContainer = () => {
    return (
        <>
            <div className="container">

                <div className="testimonials-box">
                    {/* <Testimonials/>
                    <DiscountProduct/> */}
                    <OurServices/>
                </div>

                </div>
        </>
    );
};

export default TestimonialContainer;