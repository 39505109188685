//Local
// export let API_URL = "http://192.168.197.103:8888/ArcheryMartAPI/";
// export let PRODUCT_NAME = "ARCHERY MART";
// export let PRODUCT_LOGO = "http://archerymart.in/logo.png";
// export let SIGN_IN_TEXT = "Welcome to Archery Pro! Discover our top-quality bows, arrows, and accessories to elevate your archery skills. Join us today and become part of a passionate archery community. Enjoy exclusive offers and updates tailored to your archery needs!";
// export let SIGN_UP_TEXT = "Welcome back to Archery Pro! Your go-to source for the best archery equipment and expert advice. Sign in to manage your orders, view personalized recommendations, and stay updated with the latest products and promotions.";

// ArcheryMart
export let API_URL = "https://archerymart.in/ArcheryMartAPI/";
export let PRODUCT_NAME = "ARCHERY MART";
export let PRODUCT_LOGO = "http://archerymart.in/logo.png";
export let SIGN_IN_TEXT = "Welcome to Archery Pro! Discover our top-quality bows, arrows, and accessories to elevate your archery skills. Join us today and become part of a passionate archery community. Enjoy exclusive offers and updates tailored to your archery needs!";
export let SIGN_UP_TEXT = "Welcome back to Archery Pro! Your go-to source for the best archery equipment and expert advice. Sign in to manage your orders, view personalized recommendations, and stay updated with the latest products and promotions.";

// Fish
// export let API_URL = "https://karurdiscussaquarium.com/ArcheryMartAPI/";
// export let PRODUCT_NAME = "DISCUSS AQUARIUM";
// export let PRODUCT_LOGO = "https://karurdiscussaquarium.com/IMG_20241104_174542_198.webp";
// export let SIGN_IN_TEXT = "Welcome to AquaWorld! Dive into our wide range of exotic fish, aquariums, and accessories to create your perfect underwater paradise. Join us today and become part of a community passionate about aquatic beauty. Enjoy exclusive offers and updates tailored to your aquarium needs!";
// export let SIGN_UP_TEXT = "Welcome back to AquaWorld! Your trusted destination for premium aquarium supplies and expert tips. Sign in to manage your orders, explore personalized recommendations, and stay updated with the latest aquatic products and exclusive promotions.";