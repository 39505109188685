import React from 'react';
import { API_URL } from '../../Const';
import { Link } from 'react-router-dom';
import { displayMoney } from '../../helpers/utils';

const BestSellers = ({homePageData}) => {
    
    return (
            homePageData && homePageData.best_seller?
            <div className="product-showcase">
                <h3 className="showcase-heading">best sellers</h3>
                <div className="showcase-wrapper">
                    <div className="showcase-container">
                        {
                            homePageData.best_seller.map((item, index) => {
                                return(
                                    <div className="showcase">
                                        <Link to={`/product-details/${item.product_id}`}>
                                            <a href="#" className="showcase-img-box">
                                            <img src={API_URL+item.product_primary_image_url} alt={item.product_name} width="75" height="75"
                                                className="showcase-img" />
                                            </a>
                                        </Link>
                                        <div className="showcase-content">
                                        <Link to={`/product-details/${item.product_id}`}>
                                            <a href="#">
                                                <h4 className="showcase-title">{item.product_name}</h4>
                                            </a>
                                        </Link>
                                        <div className="showcase-rating">
                                            <ion-icon name="star"></ion-icon>
                                            <ion-icon name="star"></ion-icon>
                                            <ion-icon name="star"></ion-icon>
                                            <ion-icon name="star"></ion-icon>
                                            <ion-icon name="star"></ion-icon>
                                        </div>
                                        <div className="price-box">
                                            <del>{displayMoney(item.product_actual_price)}</del>
                                            <p className="price">{displayMoney(item.product_current_price)}</p>
                                        </div>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    
                    </div>
                </div>
            </div>
            :null
    );
};

export default BestSellers;