import React from "react";
import { Link } from "react-router-dom";
import axios from 'axios'
import { useNavigate } from "react-router-dom";
import {API_URL} from '../../Const';

const ProductCard = ({ product, onRemove }) => {

  let navigate = useNavigate();

  const handleAddItem = async (product_id) => {
    const userId = localStorage.getItem('userId');
    if(userId == null || userId === "" || userId === "-1"){
        navigate("loginPage");
    } else {
        const sendAuth = await axios.post(API_URL+"addToMyCart",
        {
            user_id: userId,
            product_id: product_id,
            quantity: 1
        })
        const receive = await sendAuth.data
        if (receive.status === 'success') {
            alert("Product added to cart");
            //TODO change the cart count
        } else if(receive.status === 'failure'){
            alert(receive.message);
        } else {
            alert("Something went wrong, Please try again");
        }
    }
  };

  return (
    <div style={styles.card}>
      <img src={product.image} alt={product.name} style={styles.image} />
      <div style={styles.details}>
        <h3 style={styles.name}>{product.name}</h3>
        <p style={styles.price}>{product.price}</p>
        <p style={styles.rating}>⭐ {product.rating}</p>
        <div style={styles.actions}>
          <button style={styles.button} onClick={onRemove}>
            Remove
          </button>
          <button style={styles.actionButton}>
            <Link to={`/product-details/${product.id}`} style={styles.link}>
              <ion-icon name="eye-outline"></ion-icon>
            </Link>
          </button>
          <button style={styles.actionButton} onClick={() => handleAddItem(product.id)}>
            <ion-icon name="bag-add-outline"></ion-icon>
          </button>
        </div>
      </div>
    </div>
  );
};

const styles = {
  card: {
    border: "1px solid #ddd",
    borderRadius: "8px",
    padding: "10px",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
    backgroundColor: "#fff",
    width:"fit-content"
  },
  image: {
    width: "100%",
    height: "150px",
    objectFit: "contain",
    borderRadius: "8px",
  },
  details: {
    marginTop: "10px",
  },
  name: {
    fontSize: "16px",
    margin: "5px 0",
  },
  price: {
    color: "#2a9d8f",
    fontSize: "14px",
    margin: "5px 0",
  },
  rating: {
    color: "#e9c46a",
    margin: "5px 0",
  },
  actions: {
    display: "flex",
    justifyContent: "center",
    gap: "10px",
    marginTop: "10px",
  },
  button: {
    backgroundColor: "#e63946",
    color: "#fff",
    border: "none",
    padding: "8px 12px",
    borderRadius: "5px",
    cursor: "pointer",
  },
  actionButton: {
    backgroundColor: "#f4f4f4",
    color: "#555",
    border: "1px solid #ddd",
    padding: "8px",
    borderRadius: "5px",
    cursor: "pointer",
  },
  link: {
    color: "inherit",
    textDecoration: "none",
  },
};

export default ProductCard;
