import React from 'react';

//modestbranding - removes the YouTube logo from the player
const YouTubeVideo = ({videoId, autoplay, mute, showRelatedVideo, modestbranding, loop}) => {
    return (
        <iframe 
            width="100%" 
            height="315px" 
            src={`https://www.youtube.com/embed/${videoId}?autoplay=${autoplay}&mute=${mute}&rel=${showRelatedVideo}&modestbranding=${modestbranding}&loop=${loop}&playlist=${videoId}`}
            title="YouTube video player" 
            frameBorder="0" 
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
            allowFullScreen
        ></iframe>
    );
};

export default YouTubeVideo;