import React from 'react';
import { API_URL } from '../../Const';
import { Link } from 'react-router-dom';
import { displayMoney } from '../../helpers/utils';
import YouTubeVideo from '../YouTubeVideo/YouTubeVideo';

const HeaderBanner = ({homePageData}) => {
    return (
        homePageData && homePageData.banner_slider? 
        <div className="banner">
            <div className="container">
                <div className="slider-container has-scrollbar">
                    {
                        homePageData.banner_slider.map((item, index) => {
                            return(
                                item.banner_slider_yt_video_id !== "0" ?
                                    <div className="slider-item" key={item.banner_slider_id}>
                                        <YouTubeVideo videoId={item.youtube_video_video_id} autoplay={item.youtube_video_autoplay} mute={item.youtube_video_mute} showRelatedVideo={item.youtube_video_show_related_videos} modestbranding={item.youtube_video_remove_yt_logo} loop={item.youtube_video_loop}/>
                                    </div>
                                :
                                <div className="slider-item" key={item.banner_slider_id}>
                                    <img src={API_URL+item.banner_slider_image_url} alt={item.banner_slider_title} className="banner-img" />
                                    <div className="banner-content">
                                        <p className="banner-subtitle">{item.banner_slider_description}</p>
                                        <h2 className="banner-title">{item.banner_slider_title}</h2>
                                        <Link to={`/product-details/${item.banner_slider_product_id}`}>
                                            <a href="#" className="banner-btn">Shop now</a>
                                        </Link>
                                    </div>
                                </div>
                                
                            )
                        })
                    }
                </div>
            </div>
        </div>
        : null
    );
};

export default HeaderBanner;