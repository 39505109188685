import React from 'react';
import NewArrivals from './NewArrivals';
import Trending from './Trending';
import TopRated from './TopRated';

const MinimalProductsContainer = ({homePageData}) => {
    return (
        <div className="product-minimal">
          {
            homePageData && homePageData.new_arrivals ? <NewArrivals homePageData={homePageData}/> : null
          }
          {
            homePageData && homePageData.trending ? <Trending homePageData={homePageData}/> : null
          }
          {
            homePageData && homePageData.top_rated ? <TopRated homePageData={homePageData}/> : null
          }
        </div>
    );
};

export default MinimalProductsContainer;