import React from 'react';
import { useNavigate } from "react-router-dom";

const MobileBottomNavigation = ({ toggleSideDrawer, toggleSideDrawerMenu, favoriteCount, cartCount }) => {
    let navigate = useNavigate();
    
    const goTo = (path) => {
        navigate(path);
    }

    return (
        <div className="mobile-bottom-navigation">
            <button className="action-btn" onClick={toggleSideDrawerMenu}>
                <ion-icon name="menu-outline"></ion-icon>
            </button>

            <button className="action-btn">
                <ion-icon name="bag-handle-outline" onClick={() => goTo("cartDetails")}></ion-icon>
                <span className="count">{cartCount}</span>
            </button>

            <button className="action-btn">
                <ion-icon name="home-outline" onClick={() => goTo("")}></ion-icon>
            </button>

            <button className="action-btn">
                <ion-icon name="heart-outline" onClick={() => goTo("favoriteProducts")}></ion-icon>
                <span className="count">{favoriteCount}</span>
            </button>

            <button className="action-btn" onClick={toggleSideDrawer}>
                <ion-icon name="grid-outline"></ion-icon>
            </button>
        </div>
    );
};

export default MobileBottomNavigation;
