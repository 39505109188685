import React from 'react';
import { API_URL } from '../../Const';
import { Link } from 'react-router-dom';
import { displayMoney } from '../../helpers/utils';
import { WhatsappShareButton, WhatsappIcon } from 'react-share';
import axios from 'axios'
import { useNavigate } from "react-router-dom";

const MainProducts = ({homePageData}) => {

    let navigate = useNavigate();
    const goTo = (path) => {
        navigate(path);
    }

    const addToCart = async (product_id) => {
        const userId = localStorage.getItem('userId');
        if(userId == null || userId === "" || userId === "-1"){
            navigate("loginPage");
        } else {
            const sendAuth = await axios.post(API_URL+"addToMyCart",
            {
                user_id: userId,
                product_id: product_id,
                quantity: 1
            })
            const receive = await sendAuth.data
            if (receive.status === 'success') {
                alert("Product added to cart");
                //TODO change the cart count
            } else if(receive.status === 'failure'){
                alert(receive.message);
            } else {
                alert("Something went wrong, Please try again");
            }
        }
        
    }
    const addToFav = async (product_id) =>{ 
        const userId = localStorage.getItem('userId');
        if(userId == null || userId === "" || userId === "-1"){
            navigate("loginPage");
        } else {
            const sendAuth = await axios.post(API_URL+"addFavouriteProduct",
            {
                user_id: userId,
                product_id: product_id
            })
            const receive = await sendAuth.data
            if (receive.status === 'success') {
                alert("Product added to favourite list");
                //TODO change the fav count
            } else if(receive.status === 'failure'){
                alert(receive.message);
            } else {
                alert("Something went wrong, Please try again");
            }
        }
    }
    return (
        homePageData && homePageData.new_products ? 
            <div className="product-main">
                <h2 className="title">New Products</h2>
                <div className="product-grid">
                    {
                        homePageData.new_products.map((item, index) => {
                            return(
                                <div className="showcase">
                                    <div className="showcase-banner">
                                        <img src={API_URL+item.product_primary_image_url} alt={item.product_name} width="300" className="product-img default" />
                                        <img src={API_URL+item.product_primary_image_url} alt={item.product_name} width="300" className="product-img hover" />
                                        {index % 3 === 0 ? <p className="showcase-badge">{item.product_offer_percentage}%</p> : null}
                                        {index % 3 === 1 ? <p className="showcase-badge angle black">sale</p> : null}
                                        {index % 3 === 2 ? <p className="showcase-badge angle pink">new</p> : null}
                                        
                                        <div className="showcase-actions">
                                        <button className="btn-action" onClick={() => addToFav(item.product_id)}>
                                            <ion-icon name="heart-outline"></ion-icon>
                                        </button>
                                        <button className="btn-action">
                                            <Link to={`/product-details/${item.product_id}`}>
                                                <ion-icon name="eye-outline"></ion-icon>
                                            </Link>
                                        </button>
                                        <button className="btn-action" onClick={() => addToCart(item.product_id)}>
                                            <ion-icon name="bag-add-outline"></ion-icon>
                                        </button>
                                        <button className="btn-action">
                                        <WhatsappShareButton
                                    url={window.location.href}
                                    title={`Hi, I would like to buy ${item.product_name}. Please share more information.`}
                                    separator=" - "
                                >
                                    <WhatsappIcon size={22} round={true} />
                                </WhatsappShareButton>
                                        </button>
                                        </div>
                                    </div>
                                    <div className="showcase-content">
                                        <Link to={`/product-details/${item.product_id}`}>
                                            <a href="#" className="showcase-category">{item.product_brand_name}</a>
                                        </Link>
                                        <Link to={`/product-details/${item.product_id}`}>
                                        <h3 className="showcase-title">{item.product_name}</h3>
                                        </Link>
                                        <div className="showcase-rating">
                                        <ion-icon name="star"></ion-icon>
                                        <ion-icon name="star"></ion-icon>
                                        <ion-icon name="star"></ion-icon>
                                        <ion-icon name="star-outline"></ion-icon>
                                        <ion-icon name="star-outline"></ion-icon>
                                        </div>
                                        <div className="price-box">
                                        <p className="price">{displayMoney(item.product_current_price)}</p>
                                        <del>{displayMoney(item.product_actual_price)}</del>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        : null
    );
};

export default MainProducts;