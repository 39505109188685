import React, { useState, useEffect } from 'react';
import HeaderBanner from '../Components/Header/HeaderBanner';
import BannerCategory from '../Components/BannerCategory/BannerCategory';
import Blog from '../Components/Blog/Blog';
import TestimonialContainer from '../Components/TestimonialContainer/TestimonialContainer';

import FeaturedProduct from '../Components/ProductsContainer/FeaturedProduct';
import MinimalProductsContainer from '../Components/ProductsContainer/MinimalProducts/MinimalProductsContainer';
import MainProducts from '../Components/ProductsContainer/MainProducts';

import SidebarCategory from '../Components/Sidebar/SidebarCategory';
import BestSellers from '../Components/Sidebar/BestSellers';
import { API_URL } from '../Const';
import axios from 'axios';
import { useNavigate } from "react-router-dom";

function HomePage() {

    const [homePageData, setHomePageData] = useState(null);
    const [issideDrawerActive, setSidebarDrawerActive] = useState(false);
    const [issideDrawerMenuActive, setiSsideDrawerMenuActive] = useState(false);

    const toggleSideDrawer = () =>{
        setSidebarDrawerActive(!issideDrawerActive);
    }
    const toggleSideDrawerMenu = () =>{
        setiSsideDrawerMenuActive(!issideDrawerMenuActive);
    }

    let navigate = useNavigate();
    useEffect(() => {
        getHomePageData();
    }, []);

    const getHomePageData = async () => {
        try {
        const response = await axios.get(`${API_URL}getHomePageDetails`);
        setHomePageData(response.data.homepage_result_arr);
        console.log(response.data.homepage_result_arr);
        } catch (error) {
        console.log(error);
        }
    };

    return (
        <>
            
            <main>
                <HeaderBanner homePageData={homePageData}/>
                <BannerCategory homePageData={homePageData}/>
                <div className="product-container">
                    <div className="container">
                        <div className={`sidebar  has-scrollbar data-mobile-menu ${issideDrawerActive?"active":""}`}>
                            <SidebarCategory homePageData={homePageData} toggleSideDrawer={toggleSideDrawer} />
                            <BestSellers homePageData={homePageData}/>
                        </div>
                        <div className="product-box">
                            <MinimalProductsContainer homePageData={homePageData}/>
                            <FeaturedProduct homePageData={homePageData}/>
                            <MainProducts homePageData={homePageData}/>
                        </div>
                    </div>
                </div>
                <TestimonialContainer/>
                {/* <Blog/> */}
            </main>
            
        </>
    );
}

export default HomePage;