import React, { useState, useEffect } from 'react';
import { API_URL } from '../../Const';
import { Link } from 'react-router-dom';
import { displayMoney } from '../../helpers/utils';

const FeaturedProduct = ({homePageData}) => {
    const endDate = '2024-12-31T23:59:59';

    const calculateTimeLeft = () => {
        const now = new Date();
        const difference = new Date(endDate) - now;

        if (difference <= 0) {
            return { days: 0, hours: 0, minutes: 0, seconds: 0 };
        }

        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        return { days, hours, minutes, seconds };
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    }, [endDate]);

    return (
        homePageData && homePageData.deal_of_the_day?

        <div className="product-featured">
        <h2 className="title">Deal of the day</h2>
        <div className="showcase-wrapper has-scrollbar">
            {
                homePageData.deal_of_the_day.map((item, index) => {
                    return(
                        <div className="showcase-container">
                            <div className="showcase">
                                <div className="showcase-banner">
                                    <img src={API_URL+item.product_primary_image_url} alt={item.product_brand_name} className="showcase-img" />
                                </div>
                                <div className="showcase-content">
                                    <div className="showcase-rating">
                                        <ion-icon name="star"></ion-icon>
                                        <ion-icon name="star"></ion-icon>
                                        <ion-icon name="star"></ion-icon>
                                        <ion-icon name="star-outline"></ion-icon>
                                        <ion-icon name="star-outline"></ion-icon>
                                    </div>
                                    <Link to={`/product-details/${item.product_id}`}>
                                        <h3 className="showcase-title">{item.product_brand_name}</h3>
                                    </Link>
                                    <p className="showcase-desc">
                                    {item.product_description}
                                    </p>
                                    <div className="price-box">
                                        <p className="price">{displayMoney(item.product_current_price)}</p>
                                        <del>{displayMoney(item.product_actual_price)}</del>
                                    </div>
                                    <button className="add-cart-btn">add to cart</button>

                                    <div className="countdown-box">
                                        <p className="countdown-desc">Hurry Up! Offer ends in:</p>
                                        <div className="countdown">
                                            <div className="countdown-content">
                                                <p className="display-number">{String(timeLeft.days).padStart(2, '0')}</p>
                                                <p className="display-text">Days</p>
                                            </div>
                                            <div className="countdown-content">
                                                <p className="display-number">{String(timeLeft.hours).padStart(2, '0')}</p>
                                                <p className="display-text">Hours</p>
                                            </div>
                                            <div className="countdown-content">
                                                <p className="display-number">{String(timeLeft.minutes).padStart(2, '0')}</p>
                                                <p className="display-text">Min</p>
                                            </div>
                                            <div className="countdown-content">
                                                <p className="display-number">{String(timeLeft.seconds).padStart(2, '0')}</p>
                                                <p className="display-text">Sec</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })
            }
        
        </div>
    </div>
        
        :null
            
    );
};

export default FeaturedProduct;