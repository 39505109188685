import React, { useState } from 'react';

const MobileNavigationMenu = ({issideDrawerMenuActive,toggleSideDrawerMenu}) => {
    // const [isMenuActive, setIsMenuActive] = useState(false);

    // const handleMenuOpen = () => setIsMenuActive(true);
    // const handleMenuClose = () => setIsMenuActive(false);
    const[mens, setMensActive] = useState(false);

    const togglemen = () =>{
        setMensActive(!mens);
    }

    const[women, setwomenActive] = useState(false);

    const toggleWomen = () =>{
        setwomenActive(!women);
    }

    const[jewllery, setJewlleryActive] = useState(false);

    const toggleJewllery = () =>{
        setJewlleryActive(!jewllery);
    }

    const[perfume, setPerfumeActive] = useState(false);

    const togglePerfume = () =>{
        setPerfumeActive(!perfume);
    }


    return (
        <>
            <nav className={`mobile-navigation-menu ${issideDrawerMenuActive ? 'active' : ''}`}>

                <div className="menu-top">
                    <h2 className="menu-title">Menu</h2>

                    <button className="menu-close-btn" onClick={toggleSideDrawerMenu}>
                        <ion-icon name="close-outline"></ion-icon>
                    </button>
                </div>

                <ul className="mobile-menu-category-list">

                    <li className="menu-category">
                        <a href="#" className="menu-title">Home</a>
                    </li>

                    <li className="menu-category">

                        <button className="accordion-menu" data-accordion-btn>
                            <p className="menu-title">Men's</p>

                            <div>
                                {
                                    !mens?
                                <ion-icon onClick = {togglemen} name="add-outline" className="add-icon active" ></ion-icon>
                                :
                                <ion-icon onClick = {togglemen} name="remove-outline" className="remove-icon active"></ion-icon>
                                }
                            </div>
                        </button>

                        <ul className={`submenu-category-list ${mens?"active":""} data-accordion`}>

                            <li className="submenu-category">
                                <a href="#" className="submenu-title">Shirt</a>
                            </li>

                            <li className="submenu-category">
                                <a href="#" className="submenu-title">Shorts & Jeans</a>
                            </li>

                            <li className="submenu-category">
                                <a href="#" className="submenu-title">Safety Shoes</a>
                            </li>

                            <li className="submenu-category">
                                <a href="#" className="submenu-title">Wallet</a>
                            </li>

                        </ul>

                    </li>

                    <li className="menu-category">

                        <button className="accordion-menu" data-accordion-btn>
                            <p className="menu-title">Women's</p>

                            <div>
                                {
                                    !women?
                                <ion-icon onClick={toggleWomen} name="add-outline" className="add-icon active"></ion-icon>
                                :
                                <ion-icon onClick={toggleWomen} name="remove-outline" className="remove-icon active"></ion-icon>
}

                            </div>
                        </button>

                        <ul className={`submenu-category-list ${women?"active":""} data-accordion`}>

                            <li className="submenu-category">
                                <a href="#" className="submenu-title">Dress & Frock</a>
                            </li>

                            <li className="submenu-category">
                                <a href="#" className="submenu-title">Earrings</a>
                            </li>

                            <li className="submenu-category">
                                <a href="#" className="submenu-title">Necklace</a>
                            </li>

                            <li className="submenu-category">
                                <a href="#" className="submenu-title">Makeup Kit</a>
                            </li>

                        </ul>

                    </li>

                    <li className="menu-category">

                        <button className="accordion-menu" data-accordion-btn>
                            <p className="menu-title">Jewelry</p>

                            <div>
                                {
                                    !jewllery?
                                <ion-icon onClick={toggleJewllery} name="add-outline" className="add-icon active"></ion-icon>:
                                <ion-icon onClick={toggleJewllery} name="remove-outline" className="remove-icon active"></ion-icon>
}
                            </div>
                        </button>

                        <ul className={`submenu-category-list ${jewllery?"active":""} data-accordion`}>

                            <li className="submenu-category">
                                <a href="#" className="submenu-title">Earrings</a>
                            </li>

                            <li className="submenu-category">
                                <a href="#" className="submenu-title">Couple Rings</a>
                            </li>

                            <li className="submenu-category">
                                <a href="#" className="submenu-title">Necklace</a>
                            </li>

                            <li className="submenu-category">
                                <a href="#" className="submenu-title">Bracelets</a>
                            </li>

                        </ul>

                    </li>

                    <li className="menu-category">

                        <button className="accordion-menu" data-accordion-btn>
                            <p className="menu-title">Perfume</p>

                            <div>
                                {
                                    !perfume?
                                <ion-icon onClick={togglePerfume} name="add-outline" className="add-icon active"></ion-icon>:
                                <ion-icon onClick={togglePerfume} name="remove-outline" className="remove-icon active"></ion-icon>
}
                            </div>
                        </button>

                        <ul className={`submenu-category-list ${perfume?"active":""} data-accordion`}>

                            <li className="submenu-category">
                                <a href="#" className="submenu-title">Clothes Perfume</a>
                            </li>

                            <li className="submenu-category">
                                <a href="#" className="submenu-title">Deodorant</a>
                            </li>

                            <li className="submenu-category">
                                <a href="#" className="submenu-title">Flower Fragrance</a>
                            </li>

                            <li className="submenu-category">
                                <a href="#" className="submenu-title">Air Freshener</a>
                            </li>

                        </ul>

                    </li>

                    <li className="menu-category">
                        <a href="#" className="menu-title">Blog</a>
                    </li>

                    <li className="menu-category">
                        <a href="#" className="menu-title">Hot Offers</a>
                    </li>

                </ul>

                <div className="menu-bottom">

                    <ul className="menu-category-list">

                        <li className="menu-category">

                            <button className="accordion-menu" data-accordion-btn>
                                <p className="menu-title">Language</p>

                                <ion-icon name="caret-back-outline" className="caret-back"></ion-icon>
                            </button>

                            <ul className="submenu-category-list" data-accordion>

                                <li className="submenu-category">
                                    <a href="#" className="submenu-title">English</a>
                                </li>

                                <li className="submenu-category">
                                    <a href="#" className="submenu-title">Espa&ntilde;ol</a>
                                </li>

                                <li className="submenu-category">
                                    <a href="#" className="submenu-title">Fren&ccedil;h</a>
                                </li>

                            </ul>

                        </li>

                        <li className="menu-category">
                            <button className="accordion-menu" data-accordion-btn>
                                <p className="menu-title">Currency</p>
                                <ion-icon name="caret-back-outline" className="caret-back"></ion-icon>
                            </button>

                            <ul className="submenu-category-list" data-accordion>
                                <li className="submenu-category">
                                    <a href="#" className="submenu-title">USD &dollar;</a>
                                </li>

                                <li className="submenu-category">
                                    <a href="#" className="submenu-title">EUR &euro;</a>
                                </li>
                            </ul>
                        </li>

                    </ul>

                    <ul className="menu-social-container">

                        <li>
                            <a href="#" className="social-link">
                                <ion-icon name="logo-facebook"></ion-icon>
                            </a>
                        </li>

                        <li>
                            <a href="#" className="social-link">
                                <ion-icon name="logo-twitter"></ion-icon>
                            </a>
                        </li>

                        <li>
                            <a href="#" className="social-link">
                                <ion-icon name="logo-instagram"></ion-icon>
                            </a>
                        </li>

                        <li>
                            <a href="#" className="social-link">
                                <ion-icon name="logo-linkedin"></ion-icon>
                            </a>
                        </li>

                    </ul>

                </div>

            </nav>
        </>
    );
};

export default MobileNavigationMenu;