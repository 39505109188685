import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL } from '../../Const';
import axios from 'axios';
import { MdOutlineDeleteOutline } from "react-icons/md";

const CartPage = () => {
  const userId = localStorage.getItem("userId");
  const [cartDetailsData, setcartDetailsData] = useState(null);
  const [totalMRP, setTotalMRP] = useState(null);
  const [shippingCharges, setShippingCharges] = useState(null);

  let navigate = useNavigate(); 
  const goToCheckoutAddress = () =>{
      let path = "/checkoutAddressDetails";
      navigate(path);
  }

  const goToHome = () =>{
    let path = "/";
    navigate(path);
  }

  const addToFav = async (product_id) =>{ 
    const userId = localStorage.getItem('userId');
    if(userId){
      const sendAuth = await axios.post(API_URL+"addFavouriteProduct",
      {
          user_id: userId,
          product_id: product_id
      })
      const receive = await sendAuth.data
      if (receive.status === 'success') {
          alert("Product added to favourite list");
          //TODO change the fav count
      } else if(receive.status === 'failure'){
        alert(receive.message);
      } else {
        alert("Something went wrong, Please try again");
      }
    }
  }

  useEffect(() => {
      if(userId){
        getUserCartDetails(userId);
      }
    }, []);

    const getUserCartDetails = async (userId) => {
      try {
        const response = await axios.get(`${API_URL}getUserCartDetailsByUserId/` + userId);
        setcartDetailsData(response.data.result_Arr);
        calculateTotalMRP(response.data.result_Arr);
        
      } catch (error) {
        console.log(error);
      }
    }
    
    const handleQuantityChange = async (productId, newQty, productPrice, cart_item_id) => {
      const sendAuth = await axios.post(API_URL+"updateCartQuantity",
      {
        cart_item_id: cart_item_id,
        user_id: userId,
        quantity: newQty
      })
      const receive = await sendAuth.data
      if (receive.status === 'success') {
        const updatedCart = cartDetailsData.map((item) => {
          if (item.product_id === productId) {
            item.quantity = newQty;
          }
          return item;
        });
    
        setcartDetailsData(updatedCart);
        calculateTotalMRP(updatedCart);
      } else if(receive.status === 'failure'){
        alert(receive.message);
      } else {
        alert("Something went wrong, Please try again");
      }
    };
  
    const calculateTotalMRP = (cartItems) => {
      let total = 0;
      cartItems.forEach(item => {
        const itemQty = item.quantity || 1;
        total += item.product_current_price * itemQty;
      });
      setTotalMRP(total);
    };

  return (
    <div className="cart-container">
      <h2 style={{textAlign: "center",marginBottom: "20px",}}>My Cart</h2>
      {
        userId ? 
        
        <div className="cart-content">
        {/* Left Section - Cart Items */}
        <div className="cart-items">
          {
            cartDetailsData ?
            cartDetailsData.map((cartItem) => {
              return(
                <div className="item-details" key={cartItem.product_id}>
                  <div style={{width:"40%"}}>
                    <img src={API_URL + cartItem.product_primary_image_url} alt=""/>
                  </div>
                  <div className="item-info" style={{width:"60%"}}>
                    <h3>{cartItem.product_name}</h3>
                    <p className="price">
                      ₹{cartItem.product_current_price} <span className="original-price">₹{cartItem.product_actual_price}</span>
                    </p>
                    <p className="saved">You saved ₹{cartItem.product_actual_price - cartItem.product_current_price}!</p>
                    <div style={{display: "flex", alignItems: "center"}}>
                      <label htmlFor="quantity">Qty: </label>
                      <select 
                        id="quantity" 
                        style={{marginLeft: "5px", marginRight: "5px"}}
                        value={cartItem.quantity || 1}
                        onChange={(e) => handleQuantityChange(cartItem.product_id, parseInt(e.target.value), cartItem.product_current_price, cartItem.cart_item_id)}
                      >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                      </select>
                      <div style={{fontSize: "12px", color: "gray"}}>(₹{cartItem.product_current_price * cartItem.quantity})</div>
                    </div>
                    <div style={{display:"flex"}}>
                      <button className="btn-action" style={{fontSize: "22px", marginRight: "16px", display: "flex", alignItems: "center", backgroundColor: "rgb(230, 57, 70)", color: "white", padding: "4px", borderRadius: "5px"}}>
                        <MdOutlineDeleteOutline/> <div style={{fontSize: "10px"}}>Remove From Cart</div>
                      </button>
                      <button className="btn-action" style={{fontSize: "22px", display: "flex", alignItems: "center", backgroundColor: "#28A745  ", color: "white", padding: "4px", borderRadius: "5px"}} onClick={() => addToFav(cartItem.product_id)}>
                        <ion-icon name="heart-outline"></ion-icon> <div style={{fontSize: "10px"}}>Add To Favorite</div>
                      </button>
                    </div>
                  </div>
                </div>
              );
            })
            : null
          }
        </div>

        {/* Right Section - Price Summary */}
        <div className="price-summary">
            <h3>PRICE SUMMARY</h3>
            <p>
              <span>Total MRP (Incl. of taxes)</span> <span>₹{totalMRP}</span>
            </p>
            <p>
              <span>Shipping Charges</span>{" "}
              <span>Not available for your location</span>
            </p>
            <p className="subtotal">
              <span>Subtotal</span> <span>₹{totalMRP}</span>
            </p>
            <p className="total">
              <strong>Total</strong> <strong>₹{totalMRP}</strong>
            </p>
            <button className="checkout-btn" onClick={goToCheckoutAddress}>GO TO CHECKOUT</button>
            <button className="continue-btn" onClick={goToHome}>CONTINUE SHOPPING</button>
          </div>
        </div>
        : 
        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
          <p style={{gridColumn: "1 / -1", textAlign: "center", color: "#888"}}>Your cart is empty!</p>
          <button style={{color: "#fff", padding: "10px 20px", fontSize: "16px", border: "none", borderRadius: "5px", cursor: "pointer",}} className="banner-btn" onClick={() => navigate("/loginPage")}>
            Login
          </button>
        </div>
      }
      

      <style jsx>{`
        .cart-container {
          max-width: auto;
          margin: 30px auto;
          background-color: #fff;
          padding: 20px;
          border-radius: 10px;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        }

        h2 {
          font-size: 24px;
          margin-bottom: 20px;
        }

        h2 span {
          font-weight: normal;
          color: #555;
        }

        .cart-content {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          gap: 20px;
        }

        .cart-items {
          flex: 2;
          background-color: #f9f9f9;
          padding: 15px;
          border-radius: 8px;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
          overflow-y: auto;  /* Enable vertical scroll */
        }

        .item-details {
          display: inline-flex;
          padding: 5px;
          margin: 15px;
          background-color:#e1e1e1;
              transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out; /* Transition effect on hover */
        }
               .item-details:hover {
    transform: scale(1.05); /* Slight zoom effect */
    box-shadow:0 4px 12px rgb(44, 40, 40); /* Slight shadow to highlight */
  }


        .item-details img {
          width: 150px;
          height: 150px;
          margin-right: 20px;
          border-radius: 8px;
        }

        .item-info {
          display: flex;
          flex-direction: column;
        }

        .item-info h3 {
          font-size: 18px;
          margin: 0 0 10px;
        }

        .price {
          font-size: 18px;
          color: #333;
        }

        .original-price {
          text-decoration: line-through;
          color: #888;
          margin-left: 10px;
        }

        .saved {
          font-size: 14px;
          color: green;
        }

        #quantity,
        #quantity-2,
        #quantity-3 {
          width: 60px;
          margin: 10px 0;
        }

        
        .price-summary {
          flex: 1;
          background-color: #e1e1e1;
          padding: 15px;
          border-radius: 8px;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        }

        .price-summary h3 {
          font-size: 16px;
          margin-bottom: 10px;
        }

        .price-summary p {
          display: flex;
          justify-content: space-between;
          margin: 10px 0;
        }

        .subtotal,
        .total {
          font-size: 16px;
        }

        .total {
          font-weight: bold;
          margin-top: 10px;
        }
.checkout-btn,
.continue-btn {
  display: flex;
  width: 100%;
  padding: 12px 18px; /* Slightly increased padding for a better feel */
  border: none;
  border-radius: 8px; /* More rounded corners for a softer look */
  cursor: pointer;
  margin-top: 15px; /* Added more space between buttons */
  font-size: 18px; /* Increased font size for better readability */
  font-weight: bold;
  text-transform: uppercase; /* Uppercase text for emphasis */
  color: #fff; /* White text color */
  text-align: center;
  transition: all 0.3s ease-in-out; /* Smooth transition for hover and active states */
  justify-content: center;
}

/* Checkout Button (Red) */
.checkout-btn {
  background: linear-gradient(145deg, #c0392b, #e74c3c); /* Red gradient */
}

.checkout-btn:hover {
  background: linear-gradient(145deg, #e74c3c, #c0392b); /* Inverted gradient on hover */
  transform: translateY(-4px); /* Slight lift on hover */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Add shadow for depth */
}

.checkout-btn:active {
  transform: translateY(1px); /* Slight press effect */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Reduced shadow when pressed */
}

.checkout-btn:focus {
  outline: none;
  box-shadow: 0 0 0 4px rgba(192, 57, 43, 0.6); /* Glow effect when focused */
}

/* Continue Button (Green) */
.continue-btn {
  background: linear-gradient(145deg, #27ae60, #2ecc71); /* Green gradient */
}

.continue-btn:hover {
  background: linear-gradient(145deg, #2ecc71, #27ae60); /* Inverted gradient on hover */
  transform: translateY(-4px); /* Slight lift on hover */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Add shadow for depth */
}

.continue-btn:active {
  transform: translateY(1px); /* Slight press effect */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Reduced shadow when pressed */
}

.continue-btn:focus {
  outline: none;
  box-shadow: 0 0 0 4px rgba(39, 174, 96, 0.6); /* Glow effect when focused */
}
@media (max-width: 768px) {
  .item-details img {
    width: 100px; /* Reduce image size on mobile */
    height: 100px; /* Adjust height to match width */
    margin-right: 10px; /* Reduce the margin on mobile */
  }

 .item-details {
    padding: 10px; /* Add more padding on mobile for better spacing */
    margin: 10px; /* Adjust the margin for mobile */
    background-color: #e1e1e1; /* Lighter background on mobile for better contrast */
  }

  /* Remove hover effect on mobile */
  .item-details:hover {
    transform: none; /* No hover effect on mobile */
    box-shadow: none; /* No shadow on mobile */
  }


  .cart-content {
    flex-direction: column; /* Stack items vertically on mobile */
    margin:0px;
  }

  .cart-items{
   width: 100%; /* Make each section take up full width */
    padding-left:10px;
  }

  .item-info{
    width:60%;
    padding-left:10px;
    
    }
  .price-summary {
    width: 100%; /* Make each section take up full width */
    margin: 0px;
    
  }

  .price-summary {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .checkout-btn, .continue-btn {
    width: 100%;
    margin: 10px 0;
  }
}

        
      `}</style>
    </div>
  );
};

export default CartPage;
