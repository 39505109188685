import React, {useState, useEffect} from 'react';
import { BsExclamationCircle } from 'react-icons/bs';
// import ProductCard from '../components/product/ProductCard';
import OurServices from '../Components/TestimonialContainer/OurServices';
import EmptyView from '../Components/Common/EmptyView';
import ProductCard from '../Components/ProductCard/ProductCard';
import FilterBar from '../Components/Filters/FilterBar';
import axios from 'axios';
import { API_URL } from '../Const';
import { useParams } from 'react-router-dom';


const ProductsList = () => {
    const [productListData, setProductListData] = useState(null);
    const { categoryId } = useParams();

    useEffect(() => {
        if(!categoryId){
            getProductListData();
        } else {
            getProductListDataByCategory(categoryId);
        }
        
    }, [categoryId]);

    const getProductListData = async () => {
        try {
            const response = await axios.get(`${API_URL}getAllProductDetails`);
            setProductListData(response.data.result_Arr);
        } catch (error) {
            console.log(error);
        }
    };

    const getProductListDataByCategory = async (categoryId) => {
        try {
            const response = await axios.get(`${API_URL}getProductByCategoryId/${categoryId}`);
            setProductListData(response.data.result_Arr);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <section className="section">
                <FilterBar />

                <div className="container">
                    {
                        productListData && productListData.length ? (
                            <div className="wrapper products_wrapper">
                                {
                                    productListData.map(item => (
                                        <ProductCard key={item.product_id} product_details = {item} />
                                    ))
                                }
                            </div>
                        ) : (
                            <EmptyView icon={<BsExclamationCircle />} msg="No Results Found"/>
                        )
                    }
                </div>
            </section>

            <div className="container">
                <OurServices/>
            </div>
        </>
    );
};

export default ProductsList;